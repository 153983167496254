import moment from "moment";
import React, { forwardRef, useEffect, useState } from "react"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getTaskHistory } from "../store/task/action";


const TaskHistory = () => {
    const currentMonth = moment().format("YYYY-MM");
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(currentMonth);
    const { getTaskHistoryData } = useSelector((state) => state.task)
    const hostId = localStorage.getItem("hostId");

    useEffect(() => {
        dispatch(getTaskHistory(hostId, selectedDate))
    }, [hostId, selectedDate])

    const handleDateChange = (date) => {
        const selectedDateObject = moment(date).format("YYYY-MM");
        setSelectedDate(selectedDateObject);

    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button
            className="btn-gray rounded-pill px-2 py-1"
            style={{ border: "none" }}
            onClick={onClick}
            ref={ref}
        >
            {value}
        </button>
    ));

    return (
        <>

            <div className=" d-flex justify-content-end"
                style={{
                    marginRight: "10px",
                }}
            >
                <DatePicker
                    selected={selectedDate}
                    dateFormat="yyyy/MM"
                    showMonthYearPicker
                    onChange={(date) => handleDateChange(date)}
                    customInput={<ExampleCustomInput />}
                    style={{ fontWeight: "bold" }}
                />
            </div>

            <div className="row p-2">
                {getTaskHistoryData && getTaskHistoryData.length > 0 ? (
                    getTaskHistoryData.map((data) => {
                        return (
                            <div
                                className="table-responsive"
                                style={{
                                    border: "1px solid #4F3060",
                                    borderRadius: "14px",
                                    background: "#241330",
                                    marginBottom: "10px"
                                }}
                            >
                                <table className="table table-borderless mb-0" style={{ color: "#FFF" }}>
                                    <thead>
                                        <tr style={{ background: "#372143", borderRadius: "14px 14px 0px 0px" }}>
                                            <th style={{ fontWeight: 500, fontSize: "15px", color: "#FFF" }}>Coin Required</th>
                                            <th style={{ fontWeight: 500, fontSize: "15px", color: "#FFF" }}>Reward Coin</th>
                                            <th style={{ fontWeight: 500, fontSize: "15px", color: "#FFF" }}>Date</th>
                                            <th style={{ fontWeight: 500, fontSize: "15px", color: "#FFF" }}>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{ borderBottom: "1px solid #372143" }}>
                                            <td style={{ fontWeight: 400, fontSize: "14px" }}>{data?.task?.coinRequired}</td>
                                            <td style={{ fontWeight: 400, fontSize: "14px" }}>{data?.rCoin}</td>
                                            <td style={{ fontWeight: 400, fontSize: "14px" }}>{data?.date.split(",")[0]}</td>
                                            <td style={{ fontWeight: 400, fontSize: "14px" }}>{data?.task?.type}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        );
                    })
                ) : (
                    <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ background: "#241330" }}
                    >
                        <span>No data found.</span>
                    </div>

                )}
            </div>
        </>
    )
}

export default TaskHistory