import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { claimReward, getHostLiveHistory, getTask } from "../store/task/action";

const GetTask = () => {
  const { getTaskData, getHostLiveHistoryData } = useSelector(
    (state) => state.task
  );
  const [data, setData] = useState("");
  const dispatch = useDispatch();
  const hostId = localStorage.getItem("hostId");

  useEffect(() => {
    dispatch(getTask(hostId));
    dispatch(getHostLiveHistory(hostId));
  }, [hostId]);

  useEffect(() => {
    setData(getTaskData);
  }, [getTaskData]);

  const handleClaim = (id) => {
    dispatch(claimReward(hostId, id));
  };

  return (
    <>
      <div className="page-content">
        <div className="main-wrapper">
          <div className="bg-image">
            <div className="main-section p-2">
              <div className="row mb-2 p-3">
                <div className="col-4 d-flex align-items-center">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => window.history.back()}
                  >
                    <path
                      d="M1.18529 11.648L7.60196 18.0647C7.77484 18.2317 8.0064 18.3241 8.24674 18.322C8.48709 18.3199 8.717 18.2235 8.88696 18.0535C9.05692 17.8836 9.15332 17.6537 9.15541 17.4133C9.1575 17.173 9.0651 16.9414 8.89812 16.7685L4.04621 11.9166H20.1667C20.4098 11.9166 20.643 11.82 20.8149 11.6481C20.9868 11.4762 21.0834 11.2431 21.0834 11C21.0834 10.7568 20.9868 10.5237 20.8149 10.3518C20.643 10.1799 20.4098 10.0833 20.1667 10.0833H4.04621L8.89812 5.23137C8.98568 5.14681 9.05551 5.04566 9.10355 4.93382C9.15159 4.82198 9.17688 4.7017 9.17794 4.57999C9.179 4.45827 9.1558 4.33757 9.10971 4.22491C9.06362 4.11226 8.99555 4.00991 8.90949 3.92384C8.82342 3.83777 8.72107 3.7697 8.60842 3.72361C8.49576 3.67752 8.37506 3.65433 8.25334 3.65539C8.13163 3.65645 8.01134 3.68173 7.8995 3.72978C7.78767 3.77782 7.68652 3.84765 7.60196 3.9352L1.18529 10.3519C1.01344 10.5238 0.916904 10.7569 0.916904 11C0.916904 11.243 1.01344 11.4761 1.18529 11.648Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div className="col-4 text-center">
                  <p
                    className="text-white fw-bold mb-0"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                  >
                    Host Task
                  </p>
                </div>
              </div>

              <div className="p-3">
                <div class="container">
                  <header>
                    <p className="host-task">Host Task</p>
                  </header>
                </div>

                <div className="summary">
                  <p>
                    Today Total R-Coin Income:{" "}
                    <span>
                      {getHostLiveHistoryData?.[0]?.todayEarning || 0}
                    </span>
                  </p>
                  <p className="mt-3">
                    Today Total Audio Room Timing:{" "}
                    <span>
                      {getHostLiveHistoryData?.[0]?.audioDuration || 0}
                    </span>
                  </p>
                  <p className="mt-3">
                    Today Total Video Live Timing:{" "}
                    <span>
                      {getHostLiveHistoryData?.[0]?.videoDuration || 0}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <CountdownTimer /> */}
          {getTaskData?.length &&
            getTaskData?.map((item) => {
              const audioDurationData =
                item?.type === "audio"
                  ? getHostLiveHistoryData?.[0]?.audioDuration
                  : getHostLiveHistoryData?.[0]?.videoDuration;

                  console.log("audioDurationData", audioDurationData);
              return (
                <>
                  <div class="task-details p-3">
                    <h3>{`${item.type.charAt(0).toUpperCase()}${item.type
                      .slice(1)
                      .toLowerCase()} Task`}</h3>

                    <p class="reward">Reward - {item?.coinsRewarded} R-Coin</p>
                    <div className="p-2">
                      <p>
                        Task Total R-coin:{" "}
                        <span>{item.coinRequired ? item.coinRequired : 0}</span>
                      </p>
                      <p className="mt-3">
                        Task Remaining R-coin:{" "}
                        <span>
                          {item.coinRequired -
                            getHostLiveHistoryData?.[0]?.todayEarning >
                          0
                            ? item.coinRequired -
                              getHostLiveHistoryData?.[0]?.todayEarning
                            : "Achieved"}
                        </span>
                      </p>

                      <div className="total-remaining-minute">
                        <p>
                          Task Total Minutes Timing:{" "}
                          <span>{item?.timeRequired} Minutes</span>
                        </p>
                        <p className="mt-3">
                          Task Remaining Minutes Timing :{" "}
                          <span>
                            {item?.timeRequired - audioDurationData > 0
                              ? item?.timeRequired -
                                audioDurationData +
                                " Minutes"
                              : "Achieved"}{" "}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {(() => {
                    const AudiOrVideo =
                    item?.type === "audio"
                        ? getHostLiveHistoryData?.[0]?.audioDuration
                        : getHostLiveHistoryData?.[0]?.videoDuration;

                    let disable;
                    if (
                      AudiOrVideo >= item?.timeRequired &&
                      getHostLiveHistoryData?.[0]?.todayEarning >=
                      item?.coinRequired && !item?.isClaimed
                    ) {
                      disable = false;
                    } else {
                      disable = true;
                    }

                    

                    // Return JSX directly here with conditional opacity
                    return (
                      <div className="d-flex justify-content-center m-3">
                        <button
                          className="claim-button"
                          onClick={() => handleClaim(item?._id)}
                          disabled={
                            disable
                          }
                          style={{
                            opacity: disable ? 0.5 : 1,
                          }}
                        >
                       {item?.isClaimed ? " Already Claimed" : "Claim Coin"}   
                        </button>
                      </div>
                    );
                  })()}
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default GetTask;

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const utcNow = now.getTime() + now.getTimezoneOffset() * 60000;
      const istNow = new Date(utcNow + 5.5 * 60 * 60 * 1000);

      const istMidnight = new Date(istNow);
      istMidnight.setHours(24, 0, 0, 0);

      const timeDiff = istMidnight - istNow;

      if (timeDiff <= 0) {
        clearInterval(timer);
        setTimeLeft({ hours: "00", minutes: "00", seconds: "00" });
        return;
      }

      const seconds = Math.floor((timeDiff / 1000) % 60);
      const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
      const hours = Math.floor((timeDiff / 1000 / 60 / 60) % 24);

      setTimeLeft({
        hours: hours.toString().padStart(2, "0"),
        minutes: minutes.toString().padStart(2, "0"),
        seconds: seconds.toString().padStart(2, "0"),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="timer-container">
      <h1 className="text-center mt-2">Rest Task Timer</h1>
      <div className="timer-display">
        <div className="timer-unit">
          <span className="timer-digit">{timeLeft.hours}</span>
          <span className="timer-label">Hours</span>
        </div>
        <div className="timer-unit">
          <span className="timer-digit">{timeLeft.minutes}</span>
          <span className="timer-label">Minutes</span>
        </div>
        <div className="timer-unit">
          <span className="timer-digit">{timeLeft.seconds}</span>
          <span className="timer-label">Seconds</span>
        </div>
      </div>
    </div>
  );
};
